import React, { Component } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective, Sort } from '@syncfusion/ej2-react-grids';
import { Box } from '@mui/material';
import { errorActionCreator } from 'client/store/Error';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { alertHandlerAction } from 'client/store/AlertHandler';
import { formatValue, getDeviceType, getEncryptDecrypt, handleBizweaverAction } from 'client/components/Common/Utility';
import ColumnTemplate from './ColumnTemplate';
import * as Enums from 'client/components/Common/Enum';
import { BackdropLoading } from 'client/components/Loading';
import Cookies from 'universal-cookie';
import { callBizweaverAction } from 'client/api/clientActions';
import { loadingAction } from 'client/store/Loading';
const cookies = new Cookies();
const deviceType = getDeviceType();
class subDataList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
        };
        this.renderingMode = (deviceType === Enums.DeviceType.Mobile ? 'Vertical' : 'Horizontal');
    }
   
    componentDidMount() {
        this.getSubReportData(this.props.parentRowData, this.props.subReportDefinition, this.props.pagination)
    }

    componentDidCatch(error, inform) {
    }

    getSubReportData = async (selectedRow, subReportDefinition, pagination) => {
       
        const reportId = subReportDefinition.reportId;
        const newMappingFields = subReportDefinition.mappingFields.map((parameterMapping, i) => {
            parameterMapping.childField.fieldValue = selectedRow[parameterMapping.parentField.fieldName];
            return {
                FieldName: parameterMapping.childField.fieldName,
                Value: selectedRow[parameterMapping.parentField.fieldName]
            };
        });

        const subReportFieldMapping = {
            ReportId: reportId,
            FilterParameter: newMappingFields,
            Pagination: pagination,
            GetDataAboveThreshold:  true,
        }
        let url = 'api/ObjectData/GetReportData';
        let versagoCookie = cookies.get('Versago');
        if (versagoCookie === undefined) {
            url = `api/ObjectData/GetPublicReportData`;
        }
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(subReportFieldMapping)
            });

        if (response.status === 200) {
            const subReportData = await response.json();
                this.setState({
                    data: subReportData.result,
                    dataRequestCompleted: true,
                    loading: false,
                });
        }
        else if (response.status === 401) {
            this.props.actions.alertHandler.showErrorMessage("The Data view you selected is not available to this account.");
            this.setState({
                data: [],
                dataRequestCompleted: true,
                loading: false,
            });
        }
        else {
            this.props.actions.alertHandler.showErrorMessage("Subrecord loading failed");
            this.setState({
                data: [],
                dataRequestCompleted: true,
                loading: false,
            });
        }



    }

    template = (column, data) => {

        return (<ColumnTemplate data={data} column={column} showErrorMessage={this.props.showErrorMessage} formatValue={formatValue} />);
    }
    handleLinkTemplateClick = async (actionId, data) => {
        const linkField = this.props.subReportDefinition.reportLink.find(field => field.id === actionId)
        var parameter = "";
        if (linkField.actionType === Enums.LinkActionType.BizweaverWebservice) {
            let bizweaverRequestData = {};
            linkField.reportLinkDetails.map((rLink) => {
                const pName = rLink.parameterName;
                var pValue = rLink.parameterValue;
                if (!rLink.isStatic) {
                    pValue = (data[rLink.parameterValue] || data[rLink.parameterValue] === 0) ? data[rLink.parameterValue] : ""; 
                }
                if (typeof pValue === 'string') {
                    pValue = pValue.replaceAll("&", "&amp;").replaceAll('"', "&quot;").replaceAll("'", "&apos;").replaceAll("<", "&lt;").replaceAll(">", "&gt;");
                }
                bizweaverRequestData[pName] = pValue;
               // parameter += (parameter.length > 0 ? "," : '') + pName + ":'" + pValue + "'"
            })
            parameter = JSON.stringify(bizweaverRequestData);
            parameter = parameter.slice(1, -1);
        }
        else {
            linkField.reportLinkDetails.map((rLink) => {
                const pName = rLink.parameterName;
                var pValue = rLink.parameterValue ? rLink.parameterValue : "";
                if (!rLink.isStatic) {
                    pValue = (data[rLink.parameterValue] || data[rLink.parameterValue] === 0) ? data[rLink.parameterValue] : "";
                }

                if (linkField.actionType === Enums.LinkActionType.OtherURL) {
                    parameter += (parameter.length > 0 ? "&" : '') + pName + "=" + encodeURIComponent(pValue)
                } else {

                    if (typeof pValue === 'string') {
                        pValue = pValue.replaceAll('&', "|-|");
                    }
                    parameter += (parameter.length > 0 ? "&" : '') + pName + "=" + pValue
                }
            })
        }
        switch (linkField.actionType) {
            case Enums.LinkActionType.Forms: {
                
                if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = encodeURIComponent(response);
                    });
                }
                var Link = "/Form/";
                if (linkField.isShowInNewTab === true) {
                    Link += linkField.actionDestinationObjectId + "/1" + (parameter.length > 0 ? "?&" + parameter : '');
                    window.open(Link, "_blank")
                } else {
                    Link += linkField.actionDestinationObjectId + "/2" + (parameter.length > 0 ? "?&" + parameter : '');
                    this.props.pushActionLinkToHistory(Link);
                }
                break;
            }
            case Enums.LinkActionType.Report: {

                if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = encodeURIComponent(response);
                    });
                }
                let Link = "/Report/";
                if (linkField.isShowInNewTab === true) {
                    Link += linkField.actionDestinationObjectId + "/1" + (parameter.length > 0 ? "?&" + parameter : '');
                    window.open(Link, "_blank")

                } else {
                    Link += linkField.actionDestinationObjectId + "/2" + (parameter.length > 0 ? "?&" + parameter : '');
                    this.props.pushActionLinkToHistory(Link);
                   
                }
                break;


            }
            case Enums.LinkActionType.OtherURL: {
               
                const Link = linkField.actionDestination + (parameter.length > 0 ? "?" + parameter : '');
                if (linkField.isShowInNewTab === true) {
                    window.open(Link, "_blank")
                } else {
                    window.open(Link, "_self")
                   
                }
                break;
            } case Enums.LinkActionType.BizweaverWebservice: {
                handleBizweaverAction(linkField.actionDestination, linkField.bizweaverTaskId, parameter,this.props);
                break;
            } case Enums.LinkActionType.CrystalReport: {
               
                if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = encodeURIComponent(response);
                    });
                }
                var Link = "/CrystalReportViewer/";
                if (linkField.isShowInNewTab === true) {
                    Link += `${this.props.subReportDefinition.reportId}/${actionId}/1` + (parameter.length > 0 ? "?&" + parameter : '');
                    window.open(Link, "_blank")
                } else {
                    Link += `${this.props.subReportDefinition.reportId}/${actionId}/2` + (parameter.length > 0 ? "?&" + parameter : '');
                   
                    this.props.pushActionLinkToHistory(Link);
                }
            }
            default: { break; }
        }
        //console.log("clickin outer component", actionId, data);
    }

 linktemplate = (column, data) => {
        let reportOutputFields = this.props.subReportDefinition.reportOutputFields.filter(r => r.fieldType === "date" || r.fieldType === "datetime");
        return (<ColumnTemplate data={data} column={column} reportOutputFields={reportOutputFields}  handleLinkTemplateClick={this.handleLinkTemplateClick} showErrorMessage={this.props.showErrorMessage} formatValue={formatValue} actionType={true} />);


    }

    getColumns = () => {
        const isDevice = this.isMobileDevice();
        const columns = this.props.subReportDefinition.reportOutputFields.filter(field => field.isVisible).map(column => {
            //need to check if column is exixt in action if so pass action link details
            const LinkField = this.props.subReportDefinition.reportLink.find(field => field.actionLinkField === column.fieldName)
            if (LinkField) {
                // conditionFormula  "DocTotal>2000"
                column.actionId = LinkField.id;
                column.conditionFormula = LinkField.conditionFormula;
                return (<ColumnDirective key={column.fieldId} field={column.fieldName} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                    textAlign={isDevice ? 'left' : column.textAlign}
                    allowSorting={isDevice ? column.isDisplayTitle ? true : false : true}
                    template={this.linktemplate.bind(this, column)} ></ColumnDirective>);
            }
            else if (column.fieldFormat === Enums.FormatType.None && column.kpi.length === 0) {
                if (column.fieldType === Enums.FormatType.Date || column.fieldType === Enums.FormatType.DateTime.toLowerCase() || column.fieldType === Enums.FormatType.Time) {
                    return (<ColumnDirective key={column.fieldId} field={column.fieldName} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                        textAlign={isDevice ? 'left' : column.textAlign}
                        allowSorting={isDevice ? column.isDisplayTitle ? true : false : true}
                        template={this.template.bind(this, column)} >
                    </ColumnDirective>);
                }
                return (<ColumnDirective key={column.fieldId} field={column.fieldName} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                    textAlign={isDevice ? 'left' : column.textAlign}
                    allowSorting={isDevice ? column.isDisplayTitle ? true : false : true}
                    disableHtmlEncode={false}
                     ></ColumnDirective>);
            }
            else {
                return (<ColumnDirective key={column.fieldId} field={column.fieldName} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                    textAlign={isDevice ? 'left' : column.textAlign}
                    template={this.template.bind(this, column)} ></ColumnDirective>);
            }

        });
        // Adding new colum action links
        this.props.subReportDefinition.reportLink.filter(field => field.actionMode === Enums.ActionMode.NewColumn).map(column => {
            //need to check if column is exixt in action if so pass action link details
            column.actionId = column.id;
            columns.push((<ColumnDirective field="" showColumnMenu={column.actionName} headerText={column.actionName} template={this.linktemplate.bind(this, column)}
            ></ColumnDirective>));

        }); 
       
    
        return (
            <ColumnsDirective>
                {columns}
            </ColumnsDirective>
        );
    }

    setSortOptions() {
        let sortOptions = this.props.subReportDefinition.sortField.map(column => {
            return { field: column.fieldName, direction: column.sortingOption };

        });
        return { columns: sortOptions };
    }

    setAggregates() {

        const aggregates = this.props.subReportDefinition.reportOutputFields.map(column => {
            switch (column.aggregateOption) {
                case Enums.AggregateOptions.Sum:
                    return (
                        <AggregateColumnDirective  field={column.fieldName} type='Sum' footerTemplate={this.footerSum.bind(this, column)} ></AggregateColumnDirective>
                    );
                case Enums.AggregateOptions.Average:
                    return (
                        <AggregateColumnDirective  field={column.fieldName} type='Average' footerTemplate={this.footerAverage.bind(this, column)} ></AggregateColumnDirective>
                    );
                case Enums.AggregateOptions.Min:
                    return (
                        <AggregateColumnDirective  field={column.fieldName} type='Min' footerTemplate={this.footerMin.bind(this, column)} ></AggregateColumnDirective>
                    );
                case Enums.AggregateOptions.Max:
                    return (
                        <AggregateColumnDirective  field={column.fieldName} type='Max' footerTemplate={this.footerMax.bind(this, column)} ></AggregateColumnDirective>
                    );
                case Enums.AggregateOptions.Count:
                    return (
                        <AggregateColumnDirective  field={column.fieldName} type='Custom' customAggregate={this.customAggregateCount.bind(this, column.fieldName)} footerTemplate={this.footerCount.bind(this, column)} />
                    );

                default:
                    return null;

            }
        });

        const value = aggregates.find(obj => obj !== null);

        if (value) {
            return (
                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            {aggregates}
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
            );
        }
        else
            return null;
    }

    footerSum(column, props) {
        let value = "";
        if (props.Sum !== " ") {
            value = formatValue(column, props.Sum);
        }
        return (<span>Sum: {value}</span>);
    }
    footerAverage(column, props) {
        let value = "";
        if (props.Average !== " ") {
            value = formatValue(column, props.Average);
        }
        return (<span>Average: {value}</span>);
    }
    footerMin(column, props) {
        let value = "";
        if (props.Min !== " ") {
            if (column.fieldFormat === Enums.FormatType.Date || column.fieldFormat === Enums.FormatType.DateTime || column.fieldType === Enums.DbType.Date || column.fieldType === Enums.DbType.DateTime) {
                value = formatValue(column, props.Min.toString());
            } else {
                value = formatValue(column, props.Min);
            }

        }
        return (<span>Min: {value}</span>);
    }
    footerMax(column, props) {
        let value = "";
        if (props.Max !== " ") {
            if (column.fieldFormat === Enums.FormatType.Date || column.fieldFormat === Enums.FormatType.DateTime || column.fieldType === Enums.DbType.Date || column.fieldType === Enums.DbType.DateTime) {
                value = formatValue(column, props.Max.toString());
            } else {
                value = formatValue(column, props.Max);
            }

        }
        return (<span>Max: {value}</span>);
    }
    footerCount(column, props) {
        let value = props.Custom;
        return (<span>Count: {value}</span>)
    }
    customAggregateCount(fieldName, args) {
        const val = args.result.filter((item) => {
            return item[fieldName] !== null
        }).length;
        return val;
    }
    isMobileDevice = () => {
        return deviceType != Enums.DeviceType.Desktop ? true : false;
    }

    gridCreated() {
        const parElement = this.subGrid.element;
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord) {
            emptyRecord.firstChild.innerText = "Data Loading"
        }


    }

    resetEmptyRecordMessage = () => {
        if (this.subGrid && this.state.dataRequestCompleted && this.state.data.length ===0) {
            const parElement = this.subGrid.element;
            const emptyRecord = parElement.querySelector('.e-emptyrow');
            if (emptyRecord) {
                emptyRecord.firstChild.innerText = "No records to display"
            }
        }
    }
    gridDataBound(object) {
        if (this.subGrid && deviceType !== Enums.DeviceType.Mobile) {
            this.subGrid.autoFitColumns();
        }
    }

    render() {

        const columns = this.getColumns();
        const sortSettings = this.setSortOptions();
        const aggregates = this.setAggregates();
        const isDevice = this.isMobileDevice();
        const infiniteOptions = { initialBlocks: 2 };
        this.resetEmptyRecordMessage();
        return (

            <Box pl={1} pr={1}>
                <BackdropLoading loading={this.state.loading} />
                <div className={isDevice ? "e-bigger" : ""}>
                    <GridComponent dataSource={this.state.data}
                        ref={grid => this.subGrid = grid}
                        dataBound={this.gridDataBound.bind(this)}
                        created={this.gridCreated.bind(this)}
                    showColumnMenu={true} allowResizing={true}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    enableAdaptiveUI={isDevice}
                        rowRenderingMode={this.renderingMode}
                        infiniteScrollSettings={infiniteOptions}
                >
                    {columns}
                    {aggregates}
                    <Inject services={[Sort, Aggregate]} />
                    </GridComponent>
                    </div>
            </Box>

        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch),
            alertHandler: bindActionCreators(alertHandlerAction, dispatch),
            loadingAction: bindActionCreators(loadingAction, dispatch),
        }
    };
}
export default connect(null,
    mapDispatchToProps
)(subDataList);


